import { Controller } from "@hotwired/stimulus"
import lightGallery from 'lightgallery'

export default class extends Controller {
  static values = {
    options: Object,
    selector: String
  }

  connect () {
    this.lightGallery = lightGallery(this.element, {
      ...this.defaultOptions,
      ...this.optionsValue
    })
  }

  openGallery (event) {
    event.preventDefault()
    this.lightGallery.items[0].click()
  }

  get defaultOptions () {
    return {
      licenseKey: '2168F75C-C1C2-45FD-8C40-9193A7B8C3AA',
      thumbnail: false,
      animateThumb: false,
      showThumbByDefault: false,
      download: false,
      selector: this.selectorValue || '.item'
    }
  }
}
