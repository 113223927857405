import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["burger", "wrapper"]

  static values = {
    overflow: Boolean
  }

  initialize () {
    this.body = document.body
    this.html = document.documentElement
    this.isOpen = false
  }

  connect () {
    this.updateDom()
  }

  toggle () {
    this.isOpen = !this.isOpen

    this.updateDom()
  }

  clickOutsideHandler (e) {
    if (this.isOpen && !this.element.contains(e.target)) {
      this.isOpen = false
      this.updateDom()
    }
  }

  updateDom () {
    this.element.classList.toggle("active", this.isOpen)

    if (this.hasBurgerTarget) {
      this.burgerTarget.classList.toggle("active", this.isOpen)
    }

    if (this.overflowValue) {
      this.body.classList.toggle("overflow-hidden-body", this.isOpen)
      this.html.classList.toggle("overflow-hidden-html", this.isOpen)
    }
  }
}
