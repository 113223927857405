import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { patch } from "@rails/request.js"

export default class extends Controller {
  static values = {
    resourceName: String,
    attributeName: String,
    responseKind: String,
    animation: Number,
    handle: String
  }

  initialize () {
    this.attributeNameValue ||= "position"
    this.responseKindValue ||= "js"
    this.animationValue ||= 500
  }

  connect () {
    this.sortable = new Sortable(this.element, this.options)
  }

  async notifyBackend (url, attributeValue) {
    if (!url) return

    const param = this.resourceNameValue ? `${this.resourceNameValue}[${this.attributeNameValue}]` : this.attributeNameValue
    const data = new FormData()
    data.append(param, attributeValue)

    const { response } = await patch(url, { body: data, responseKind: this.responseKindValue })
    return response
  }

  get options () {
    return {
      animation: this.animationValue,
      handle: this.handleValue,
      onEnd: (event) => this.notifyBackend(event.item.dataset.sortableUpdateUrl, event.newIndex + 1)
    }
  }
}
