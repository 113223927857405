import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

import ScrollReveal from "stimulus-scroll-reveal"
import Reveal from "stimulus-reveal-controller"
import Remote from "stimulus-remote-rails"

window.Stimulus = Application.start()
const context = require.context("@controllers", false, /\.js$/)

Stimulus.load(definitionsFromContext(context))

Stimulus.register("scroll-reveal", ScrollReveal)
Stimulus.register("reveal", Reveal)
Stimulus.register("remote", Remote)
