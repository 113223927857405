import RemoteRails from "stimulus-remote-rails"

export default class extends RemoteRails {
  replace (event) {
    super.replace(event)
    const [,, xhr] = event.detail

    history.pushState(null, null, xhr.responseURL)
  }
}
