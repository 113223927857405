import { Controller } from "@hotwired/stimulus"
import { debounce } from 'lodash'

export default class extends Controller {
  static targets = [
    'input',
    'option',
    'results',
    'value'
  ]

  connect () {
    this.resultsTarget.hidden = true

    this.inputTarget.setAttribute('autocomplete', 'off')
    this.inputTarget.setAttribute('spellcheck', 'false')

    this.mouseInResults = false

    this.debouncedFetchResult = debounce(this.fetchResults.bind(this), 300)

    this.inputTarget.addEventListener('focus', this.openResults.bind(this))
    this.inputTarget.addEventListener('input', this.debouncedFetchResult)
    this.resultsTarget.addEventListener('mousedown', this.preventResultsClose.bind(this))
    this.inputTarget.addEventListener('blur', this.closeResults.bind(this))
  }

  disconnect () {
    this.inputTarget.removeEventListener('focus', this.openResults)
    this.inputTarget.removeEventListener('input', this.debouncedFetchResult)
    this.resultsTarget.removeEventListener('mousedown', this.preventResultsClose)
    this.inputTarget.removeEventListener('blur', this.closeResults)
  }

  preventResultsClose () {
    this.mouseInResults = true
    this.resultsTarget.addEventListener('mouseup', () => (this.mouseInResults = false), { once: true })
  }

  fetchResults () {
    const query = this.inputTarget.value.trim()

    if (!query || query.length < this.minLength) return
    if (!this.src) return

    this.closeResults()
    this.resultsTarget.innerHTML = null

    const url = new URL(this.src, window.location.href)
    const params = new URLSearchParams(url.search.slice(1))
    params.append(this.searchKey, query)
    url.search = params.toString()

    const headers = { 'X-Requested-With': 'XMLHttpRequest', Accept: this.contentType }

    this.element.dispatchEvent(new CustomEvent('loadstart'))

    fetch(url.toString(), { headers })
      .then(response => response.text())
      .then(html => {
        this.resultsTarget.innerHTML = html
        this.element.dispatchEvent(new CustomEvent('load'))
        this.element.dispatchEvent(new CustomEvent('loadend'))
        this.openResults()
      })
      .catch(() => {
        this.element.dispatchEvent(new CustomEvent('error'))
        this.element.dispatchEvent(new CustomEvent('loadend'))
      })
  }

  openResults () {
    if (!this.resultsTarget.hidden) return
    this.resultsTarget.hidden = false
    this.element.setAttribute('aria-expanded', 'true')
  }

  closeResults () {
    if (this.mouseInResults) return
    if (this.resultsTarget.hidden) return
    this.resultsTarget.hidden = true
    this.inputTarget.removeAttribute('aria-activedescendant')
    this.element.setAttribute('aria-expanded', 'false')
  }

  selectObject (event) {
    event.preventDefault()

    this.valueTarget.value = event.currentTarget.dataset.value
    this.inputTarget.value = event.currentTarget.textContent
    this.closeResults()
  }

  get src () {
    return this.data.get('url')
  }

  get searchKey () {
    return this.data.get('search') || 'q'
  }

  get contentType () {
    return this.data.get('content-type') || 'text/x.autocomplete+html'
  }

  get minLength () {
    const minLength = this.data.get('min-length')
    if (!minLength) {
      return 0
    }
    return parseInt(minLength, 10)
  }
}
