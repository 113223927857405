import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  connect () {
    this.element.addEventListener("change", () => {
      const form = this.element.closest("form")
      if (form.dataset.remote === 'true') {
        Rails.fire(form, 'submit')
      } else {
        form.submit()
      }
    })
  }
}
